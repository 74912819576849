<style scoped lang="less">
.main {
  height: 100%;
  min-width: 1440px;
}

a.logo {
  height: 80px;
  line-height: 80px;
  text-align: center;
  color: #333;
  font-size: 20px;
  display: block;
}
.header {
  background-color: #f1f1f1;
  position: relative;
  padding: 0 20px;
  border-bottom: 1px solid #f2f2f2;
  height: 48px;
  line-height: 48px;
}
.menu {
  text-align: right;
  height: 64px;
  line-height: 64px;
  background-color: #12192c;
  display: inline-block;
  .ivu-menu-item {
    float: none;
    display: inline-block;
    border: 1px solid #808080;
    border-radius: 15px;
    line-height: 30px;
    padding: 0 16px;
    margin-right: 10px;
  }
  .ivu-menu-item-active,
  .ivu-menu-item-selected {
    background-color: #4f7df9;
    border-color: #4f7df9;
  }
}
.user-info {
  height: 24px;
  line-height: 24px;
  position: absolute;
  top: 12px;
  right: 20px;
}
.r-top-menu {
  a {
    display: block;
    line-height: 36px;
    text-align: center;
    border-bottom: 1px solid #f2f2f2;
  }
  a:last-child {
    border-bottom: none;
  }
}
.ivu-layout {
  background-color: #fff;
  height: 100%;
}
</style>
<style lang="less">
html,
body {
  height: 100%;
}
</style>
<template>
  <div class="main">
    <Sider
      :style="{
        position: 'fixed',
        height: '100vh',
        left: 0,
        overflow: 'auto',
        zIndex: 1100,
      }"
    >
      <!--<a class="logo" href="/index"><img src="../img/logo.png" width="150" style="margin-top:15px;"></a>-->
      <a class="logo" href="/index" style="font-size:18px;">智能硬件管理系统</a>
      <Menu :active-name="active" theme="dark" width="auto" :open-names="open">
        <template v-for="submenu in subMenus">
          <!--<MenuItem :name="submenu.subName" :to="submenu.routerLink" :target="submenu.dataView ? '_blank' : '_self'">-->
          <!--<Icon :type="submenu.icon" />-->
          <!--<span>{{submenu.title}}</span>-->
          <!--</MenuItem>-->
          <Submenu v-if="submenu.menuItems" :name="submenu.subName">
            <template slot="title">
              <Icon :type="submenu.icon" />
              <span>{{ submenu.title }}</span>
            </template>
            <template v-for="menuItem in submenu.menuItems">
              <MenuItem
                :name="menuItem.name"
                :to="menuItem.routerLink"
                :target="menuItem.dataView ? '_blank' : '_self'"
              >
                <!--<Icon :type="menuItem.icon" />-->
                <span>{{ menuItem.menuTitle }}</span>
              </MenuItem>
            </template>
          </Submenu>
        </template>
      </Menu>
    </Sider>
    <Layout :style="{ marginLeft: '200px' }">
      <Header class="header">
        <Breadcrumb>
          <BreadcrumbItem to="/index">首页</BreadcrumbItem>
          <BreadcrumbItem v-for="v in breadList" :key="v.path" :to="v.path">{{
            v.name
          }}</BreadcrumbItem>
        </Breadcrumb>
        <Poptip
          trigger="hover"
          class="user-info"
          placement="bottom-end"
          v-model="popVisible"
        >
          <Avatar icon="md-person" size="small" />
          <a href="javascript:void(0)"
            ><span style="padding-left: 5px;font-size:13px;">{{
              curInfo
            }}</span></a
          >
          <div class="r-top-menu" slot="content">
            <a href="javascript:void(0)" @click="updatePassword">修改密码</a>
            <a href="javascript:void(0)" @click="loginOut">退出登录</a>
          </div>
        </Poptip>
      </Header>
      <Content>
        <router-view></router-view>
      </Content>
    </Layout>
    <Modal
      v-model="passwordBoxShow"
      @on-cancel="closePasswordPop"
      title="修改密码"
    >
      <Form
        ref="formCustom"
        :model="formCustom"
        :rules="ruleCustom"
        :label-width="80"
      >
        <Form-item label="原密码" prop="oldPasswd">
          <Input type="password" v-model="formCustom.oldPasswd"></Input>
        </Form-item>
        <Form-item label="新密码" prop="newPasswd">
          <Input type="password" v-model="formCustom.newPasswd"></Input>
        </Form-item>
        <Form-item label="确认密码" prop="passwdCheck">
          <Input type="password" v-model="formCustom.passwdCheck"></Input>
        </Form-item>
      </Form>
      <div slot="footer">
        <Button
          type="primary"
          size="large"
          long
          :loading="passwordLoading"
          @click="updatePasswordDo('formCustom')"
          >保存</Button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { ajax, checkAuth } from '../libs/util'
import { getMenuList } from '../libs/main-menu.js'
import { VERSION } from '../libs/const.js'
const _ = require('lodash')

export default {
  components: {
    // shrinkableMenu,
  },

  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else {
        if (value.length < 6) {
          callback(new Error('新密码长度不能小于6位！'))
          // }else if(!this.checkPassword(value)){
          //     callback(new Error('密码必须是6-20位的字母和数字组合'));
        } else {
          callback()
        }
      }
    }
    const validatePassCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.formCustom.newPasswd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }

    const namePathMap = () => {
      let routerMap = {}
      getMenuList().subMenus.forEach((item) => {
        if (!!item.menuItems.length) {
          item.menuItems.forEach((eachMenuItem) => {
            routerMap[eachMenuItem.routerLink] = eachMenuItem.name
          })
        }
      })

      return routerMap
    }

    //menuList.subMenus
    //this.submenuList = [...getMenuList().subMenus];
    return {
      checkAuth: checkAuth,
      shrink: false,
      subMenus: getMenuList().subMenus,
      curInfo: Cookies.get('name'),
      breadList: [],
      spanLeft: 3,
      spanRight: 21,
      popVisible: false,
      manageVisible: false,
      passwordBoxShow: false,
      passwordLoading: false,
      formCustom: {
        oldPasswd: '',
        newPasswd: '',
        passwdCheck: '',
      },
      ruleCustom: {
        oldPasswd: [
          { required: true, message: '请输入原密码', trigger: 'blur' },
          {
            type: 'string',
            min: 6,
            message: '密码长度不能小于6位',
            trigger: 'blur',
          },
        ],
        newPasswd: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' },
        ],
        passwdCheck: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' },
          { validator: validatePassCheck, trigger: 'blur' },
        ],
      },
      version: VERSION,
      nowSelectRouter: {},
      tab0: true,
      open: ['1', '1-1', '1-2', '4', '4-1'],
      active: '',
      namePathObj: namePathMap(),
    }
  },
  beforeCreate() {},
  watch: {
    $route() {
      this.getBreadcrumb()
      this.setCurrentMenu()
    },
  },
  computed: {},
  methods: {
    closePasswordPop() {
      this.$refs['formCustom'].resetFields()
    },
    updatePassword() {
      this.passwordBoxShow = true
      this.popVisible = false
    },
    updatePasswordDo(name) {
      this.passwordLoading = true
      this.$refs[name].validate((valid) => {
        if (valid) {
          ajax.post(
            '/user/reset_password',
            {
              password: this.formCustom.oldPasswd,
              newPassword: this.formCustom.newPasswd,
            },
            (res) => {
              this.$Message.success('密码修改成功!')
              this.passwordBoxShow = false
              this.passwordLoading = false
              this.$refs['formCustom'].resetFields()
              Cookies.set('token', '', { path: '/' })
              this.$router.push({ path: '/' })
            },
            (error) => {
              this.passwordLoading = false
              if (error.message == '密码错误') {
                this.$Modal.error({
                  title: '错误提示',
                  content: '您输入的原密码错误，请确定后重新输入',
                })
              } else {
                this.$Message.error(error.message || '服务器异常，请重试')
              }
            },
          )
        } else {
          this.passwordLoading = false
        }
      })
    },
    loginOut() {
      ajax.post(
        '/user/logout',
        {},
        (res) => {
          Cookies.set('token', '', { path: '/' })
          this.$router.push({ path: '/' })
        },
        (error) => {
          this.$Message.error(error.message || '服务器异常，请重试')
        },
      )
    },
    checkPassword(v) {
      return /([a-zA-Z]+\d)|(\d+[a-zA-Z])/.test(v)
    },
    getBreadcrumb() {
      //console.log(this.$route.matched);
      let matched = this.$route.matched
      if (matched.length > 1 && matched[1].name === '首页') {
        this.breadList = []
      } else {
        this.breadList = matched.slice(1)
      }
    },
    jumpKanban(url) {},
    setCurrentMenu() {
      this.active =
        (this.namePathObj && this.namePathObj[this.$route.path]) || ''
    },
  },
  mounted() {
    this.getBreadcrumb()
    this.setCurrentMenu()
  },
}
</script>
