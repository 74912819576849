/**
 * Created by kuaihuoyun on 2018/7/31.
 */
'use strict';

// 版本号
export const VERSION = '1.0.0';
export const AMAPKEY = 'afdf66aeee932c1b1ff251a422b3c176';

export const InvoiceApplyState = [
    '未申请',
    '已申请',
    '已开票',
    '已撤销申请',
];
