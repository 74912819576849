// 导航栏数据
import { checkAuth } from './util'

export function getMenuList() {
  return {
    subMenus: [
      {
        title: '智能硬件',
        icon: 'md-list',
        subName: '1',
        menuItems: [
          {
            name: '1-1',
            routerLink: '/manage/device-query',
            menuTitle: '设备管理',
          },
          {
            name: '1-2',
            routerLink: '/devices/trail_back',
            menuTitle: '轨迹回放',
          },
          {
            name: '1-3',
            routerLink: '/monitor/list',
            menuTitle: '自动建单监控',
          },
        ],
      },
      //   {
      //     title: '客户管理',
      //     icon: 'md-list',
      //     subName: '2',
      //     menuItems: [
      //       {
      //         check: checkAuth('user-query'),
      //         name: '2-1',
      //         routerLink: '/user/list',
      //         menuTitle: '客户列表',
      //       },
      //     ],
      //   },
      //   {
      //     title: '监控中心',
      //     icon: 'md-list',
      //     subName: '3',
      //     menuItems: [
      //       {
      //         name: '3-1',
      //         routerLink: '/monitor',
      //         menuTitle: '监控中心',
      //       },
      //     ],
      //   },
      {
        title: '企业配置',
        icon: 'md-list',
        subName: '4',
        menuItems: [
          {
            name: '4-1',
            routerLink: '/config',
            menuTitle: '企业配置',
          },
        ],
      },
      // {
      //     title:"系统设置",
      //     icon:"md-settings",
      //     subName:"4",
      //     menuItems:[
      // {
      //     name:"4-1",
      //     routerLink:"/set/base-set",
      //     menuTitle:"基本设置",
      // },
      // {
      //     check:checkAuth('user-query'),
      //     name:"4-1",
      //     routerLink:"/user/list",
      //     menuTitle:"用户管理",
      // },
      // {
      //     check:checkAuth('user_group-query'),
      //     name:"4-2",
      //     routerLink:"/user/user_group",
      //     menuTitle:"权限管理",
      // },
      //     ]
      // },
    ],
  }
}
